import { Subject, mergeMap, filter, tap } from 'rxjs';
import { useObservable, pluckFirst, useSubscription } from 'observable-hooks';
import { DialogButtonType } from '@/components/dialog/dialog';
import { showDialog } from '@/store/slices/dialog.slice';
import { removeToken } from '@/store/slices/user.slice';
import { RootState } from '@/store/store.config';
import { AxiosHeaders, AxiosResponse } from 'axios';
import Axios from 'axios-observable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function useInterceptors() {
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.userStore.token);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const t$ = useObservable(pluckFirst, [t]);
    const dialogTrigger$ = useObservable(() => new Subject<unknown>());
    useSubscription(
        useObservable(() =>
            dialogTrigger$.pipe(
                mergeMap(() => t$.pipe(filter((v) => !v('Lbl_Button_Error').includes('_undefined')))),
                tap((mapping) => {
                    dispatch(
                        showDialog({
                            title: mapping('Lbl_Button_Error'),
                            content: mapping('Msg_API_Server_Error'),
                            type: DialogButtonType.Error,
                        }),
                    );
                }),
            ),
        ),
    );

    useEffect(() => {
        Axios.interceptors.request.use(
            (config) => {
                if (config && config.headers) {
                    (config.headers as AxiosHeaders).set('token', token);
                }

                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            },
        );
        Axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                const resp = error.response as AxiosResponse;
                const redirectCodes = [403];
                if (redirectCodes.includes(resp.status)) {
                    // removeToken();
                    dispatch(removeToken());
                    navigate('/logon');
                }

                if (redirectCodes.includes(resp.status)) {
                    // removeToken();
                    dispatch(removeToken());
                    navigate('/logon');
                }
                const errorCodes = [500];

                if (errorCodes.includes(resp.status)) {
                    dialogTrigger$.next(null);
                }
                return Promise.reject(error);
            },
        );

        return () => {
            Axios.interceptors.request.clear();
            Axios.interceptors.response.clear();
        };
    }, [token, t]);
}

export default useInterceptors;
