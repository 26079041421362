export type NumType = number | string;

export const RoundType = {
    Round: 'Round', // 四捨五入
    Celi: 'Celi', // 最小整數(無條件進位)
    Floor: 'Floor', // 最大整數(無條件捨去)
} as const;

export type IRoundType = keyof typeof RoundType;

export class FloatHelper {
    private static _boundaryCheckingState = true;
    /**
     * 把錯誤的數據轉正
     * strip(0.09999999999999998)=0.1
     */
    public static strip(num: NumType, precision = 15): number {
        return +parseFloat(Number(num).toPrecision(precision));
    }

    /**
     * Return digits length of a number
     */
    public static digitLength(num: NumType): number {
        // Get digit length of e
        const eSplit = num.toString().split(/[eE]/);
        const len = (eSplit[0].split('.')[1] || '').length - +(eSplit[1] || 0);
        return len > 0 ? len : 0;
    }

    /**
     * 把小數轉成整數，支援科學計數法。如果是小數則放大成整數
     */
    public static float2Fixed(num: NumType): number {
        if (num.toString().indexOf('e') === -1) {
            return Number(num.toString().replace('.', ''));
        }
        const dLen = FloatHelper.digitLength(num);
        return dLen > 0 ? FloatHelper.strip(Number(num) * Math.pow(10, dLen)) : Number(num);
    }

    /**
     * 檢測數字是否溢出
     */
    public static checkBoundary(num: number) {
        if (FloatHelper._boundaryCheckingState) {
            if (num > Number.MAX_SAFE_INTEGER || num < Number.MIN_SAFE_INTEGER) {
                // if (gv.isDebug) {
                //   console.warn(`${num} is beyond boundary when transfer to integer, the results may not be accurate`);
                // }
            }
        }
    }

    public static enableBoundaryChecking(flag = true) {
        FloatHelper._boundaryCheckingState = flag;
    }

    /**
     * 精確加法
     */
    public static plus(num1: NumType, num2: NumType, ...others: NumType[]): number {
        if (others.length > 0) {
            return FloatHelper.plus(FloatHelper.plus(num1, num2), others[0], ...others.slice(1));
        }
        const baseNum = Math.pow(10, Math.max(FloatHelper.digitLength(num1), FloatHelper.digitLength(num2)));
        return (FloatHelper.times(num1, baseNum) + FloatHelper.times(num2, baseNum)) / baseNum;
    }

    /**
     * 精確減法
     */
    public static minus(num1: NumType, num2: NumType, ...others: NumType[]): number {
        if (others.length > 0) {
            return FloatHelper.minus(FloatHelper.minus(num1, num2), others[0], ...others.slice(1));
        }
        const baseNum = Math.pow(10, Math.max(FloatHelper.digitLength(num1), FloatHelper.digitLength(num2)));
        return (FloatHelper.times(num1, baseNum) - FloatHelper.times(num2, baseNum)) / baseNum;
    }

    /**
     * 精確乘法
     */
    public static times(num1: NumType, num2: NumType, ...others: NumType[]): number {
        if (others.length > 0) {
            return FloatHelper.times(FloatHelper.times(num1, num2), others[0], ...others.slice(1));
        }
        const num1Changed = FloatHelper.float2Fixed(num1);
        const num2Changed = FloatHelper.float2Fixed(num2);
        const baseNum = FloatHelper.digitLength(num1) + FloatHelper.digitLength(num2);
        const leftValue = num1Changed * num2Changed;

        FloatHelper.checkBoundary(leftValue);

        return leftValue / Math.pow(10, baseNum);
    }

    /**
     * 精確除法
     */
    public static divide(num1: NumType, num2: NumType, ...others: NumType[]): number {
        if (others.length > 0) {
            return FloatHelper.divide(FloatHelper.divide(num1, num2), others[0], ...others.slice(1));
        }
        const num1Changed = FloatHelper.float2Fixed(num1);
        const num2Changed = FloatHelper.float2Fixed(num2);
        // console.log('FloatHelper.digitLength(num2)',num2Changed,num1Changed ,FloatHelper.digitLength(num1),FloatHelper.digitLength(num2))
        FloatHelper.checkBoundary(num1Changed);
        FloatHelper.checkBoundary(num2Changed);
        return FloatHelper.times(num1Changed / num2Changed, FloatHelper.strip(Math.pow(10, FloatHelper.digitLength(num2) - FloatHelper.digitLength(num1))));
    }

    /**
     * Round // 四捨五入
     * Celi // 最小整數(無條件進位)
     * Floor // 最大整數(無條件捨去)
     */

    public static floatFixed(num: number, round: IRoundType, digits: number): number {
        const sign = num >= 0 ? 1 : -1;
        num = Math.abs(num);

        const numTimes = FloatHelper.times(num, Math.pow(10, digits));

        switch (round) {
            case 'Round':
                return FloatHelper.divide(Math.round(numTimes), Math.pow(10, digits)) * sign;

            case 'Celi':
                return FloatHelper.divide(Math.ceil(numTimes), Math.pow(10, digits)) * sign;

            case 'Floor':
                return FloatHelper.divide(Math.floor(numTimes), Math.pow(10, digits)) * sign;

            default:
                return num;
        }
    }
}
